<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        profileAcademicTraining.perfilFormacionAcademicaId == 0
          ? $t("Routes.ProfileAcademicTrainingAdd")
          : $t("Routes.ProfileAcademicTrainingEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileAcademicTraining.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileAcademicTraining.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="profileAcademicTraining.perfilId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="profiles"
                  :reduce="(option) => option.perfilId"
                  @search="searchProfiles"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.profileAcademicTraining.columns.academicTraining')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileAcademicTraining.columns.academicTraining')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="profileAcademicTraining.formacionAcademicaId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="academicTrainings"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.profileAcademicTraining.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="profileAcademicTraining.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingProfileAcademicTraining" small class="mr-1" />
            {{ profileAcademicTraining.perfilFormacionAcademicaId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import optionService from '@/services/option.service'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import router from '@/router'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  setup() {
    const profiles = ref([])
    const academicTrainings = ref([])
    const addingProfileAcademicTraining = ref(false)
    const profileAcademicTraining = ref({
      perfilFormacionAcademicaId: 0,
      perfilId: null,
      formacionAcademicaId: null,
      descripcion: '',
    })

    const blankProfileAcademicTraining = {
      perfilFormacionAcademicaId: 0,
      perfilId: null,
      formacionAcademicaId: null,
      descripcion: '',
    }

    const {
      fetchProfileAcademicTraining,
      createProfileAcademicTraining,
      updateProfileAcademicTraining,
      fetchProfiles,
    } = humanResources()

    const {
      fetchOptions,
    } = optionService()

    const resetProfileAcademicTraining = () => {
      profileAcademicTraining.value = JSON.parse(JSON.stringify(blankProfileAcademicTraining))
    }

    if (router.currentRoute.params.perfilFormacionAcademicaId) {
      fetchProfileAcademicTraining(router.currentRoute.params.perfilFormacionAcademicaId, data => {
        profileAcademicTraining.value = data
      })
    } else {
      profileAcademicTraining.value = JSON.parse(JSON.stringify(blankProfileAcademicTraining))
    }
    const searchProfiles = text => {
      fetchProfiles({ searchQuery: text, limit: 50 }, data => {
        profiles.value = data.perfiles
      })
    }
    fetchProfiles({ limit: 50 }, data => {
      profiles.value = data.perfiles
    })
    fetchOptions({ nombreDominio: 'RecursosHumanosFormacionAcademica' }, data => {
      academicTrainings.value = data
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetProfileAcademicTraining)

    const onSubmit = () => {
      addingProfileAcademicTraining.value = true
      if (profileAcademicTraining.value.perfilFormacionAcademicaId === 0) {
        createProfileAcademicTraining(profileAcademicTraining.value, () => {
          addingProfileAcademicTraining.value = false
          router.push({ name: 'apps-human-resources-profile-academic-training-list' })
        })
      } else {
        updateProfileAcademicTraining(profileAcademicTraining.value.perfilFormacionAcademicaId, profileAcademicTraining.value, () => {
          addingProfileAcademicTraining.value = false
          router.push({ name: 'apps-human-resources-profile-academic-training-list' })
        })
      }
    }

    return {
      profiles,
      academicTrainings,
      profileAcademicTraining,
      addingProfileAcademicTraining,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
      searchProfiles,

    }
  },
}
</script>
